@media only screen and (max-width: 480px) {
  .hero-section {
    width: 100% !important;
    // padding: 0 27px !important;

    .hero-content {
      padding: 32px 0 !important; // Reduce padding

      .hero-welcome-section {
        width: 85% !important;
        margin: 0 auto !important;
        justify-content: space-between !important;

        .welcome {
          font-size: 12px !important;
        }

        .description {
          font-size: 12px !important;
        }
      }
    }

    .hero-title {
      font-size: 32px !important; // Further reduce font size
      line-height: 40px !important;
    }

    .hero-description {
      font-size: 14px !important; // Smaller font size
      line-height: 20px !important;
      padding: 20px 50px 60px !important;
    }

    .hero-btn-container {
      width: 75% !important;
      padding: 0px 50px !important;

      .hero-button {
        width: 100% !important; // Take full width
      }
    }
  }
}

.hero-section {
  position: relative;
  // height: 500px;
  overflow: hidden;
  // width: 100%;

  .hero-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -3;
  }

  .hero-content {
    height: 100%;
    width: 100%;
    padding: 56px 0;

    z-index: 999;

    .hero-welcome-section {
      display: flex;
      /* justify-content: center; */
      border-radius: 4px;
      border: 0.5px solid #ccc;
      width: 24%;
      margin: 20px auto;
      padding: 7px;
      gap: 20px;

      .welcome {
        color: #000;
        // font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 5px;
      }

      .description {
        border: none;
        color: #000;
        // font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
        padding: 5px;
        border-radius: 4px;
        border: 0.5px solid #ccc;
      }
    }
  }

  .hero-title {
    color: #0c3557;
    text-align: center;
    // font-family: Gilroy-ExtraBold;
    font-size: 68px;
    font-style: bold;
    font-weight: 600;
    line-height: 81.6px;
    /* 120% */
    letter-spacing: 1.36px;
    width: 100%;
    margin-bottom: 24px;
  }

  .hero-description {
    color: #090808;
    text-align: center;
    // font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;

    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px 282px 60px;
    box-sizing: border-box;
  }

  .hero-btn-container {
    display: flex;
    width: 100%;
    justify-content: center;

    .hero-button {
      border: none;
      border-radius: 8px;
      background: #0a8394;
      display: flex;
      width: 447px;
      height: 60px;
      padding: 12px 24px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      // font-family: "Noto Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      transition: color 0.2s ease;
      cursor: pointer;

      &:hover {
        // background-color: #e3e3e3;
        transform: scale(1.05);
        transition: transform 0.2s ease-in-out,
          background-color 0.2s ease-in-out;
      }
    }
  }
}