@media only screen and (max-width : 480px) {
  .top-header {
    padding: 0px 24px !important;
    width: 100% !important;

    .header-content {

      .burger-menu {
        display: flex !important;
      }

      .header-actions {
        // display: none;
        display: none !important;
        flex-direction: column !important;
        position: absolute !important;
        top: 60px !important;
        right: 20px !important;
        background-color: #fff !important;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
        border-radius: 8px !important;
        padding: 10px !important;
        gap: 10px !important;
        z-index: 100 !important;

        .action-btn {
          width: 100% !important;
          text-align: left !important;
          padding: 10px !important;
        }
      }

      .header-actions.show-menu {
        display: flex !important;
      }

      .logo {


        img {}
      }


    }
  }
}


.top-header {
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 62px;
  box-sizing: border-box;
  width: 100%;
  padding: 0px 100px;

  .header-content {
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;

    .logo {
      height: 24px;

      img {
        height: 100%;
        width: auto;
        object-fit: contain;
      }
    }

    .burger-menu {
      display: none;
      cursor: pointer;

      .menu-icon {
        font-size: 24px;
        color: #333;
      }
    }

    .header-actions {
      display: flex;
      gap: 20px;
      align-items: center;

      .action-btn {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 8px 12px;
        border: none;
        background: none;
        color: #656565;
        cursor: pointer;
        font-size: 14px;
        transition: color 0.2s ease;


        &:hover {
          // background-color: #e3e3e3;
          transform: scale(1.1);
          transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
        }
      }

      .apply-now-btn {
        border-radius: 45px;
        background: #0A8394;
        color: #fff;
      }
    }
  }
}

.mobile-menu-icon {
  font-size: 24px;
  cursor: pointer;
}

.mobile-dropdown-menu {
  min-width: 200px;
}