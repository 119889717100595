.Page-container {
  width: 100%;

  .header-container {
    width: 100%;
  }

  .hero-section-container {
    width: 100%;
  }

  .culture-section-container {
    width: 100%;
  }

  .testimonial-section-container {
    width: 100%;
  }

  .explorejobs-section-container {
    width: 100%;
  }

  .footer-section-container {
    width: 100%;
  }
}