// WorkplaceBenefits.scss

@media only screen and (max-width : 480px) {
  .workplace-benefits {
    width: 100%;
    padding: 0px 12px !important;
    box-sizing: border-box !important;

    .title {
      font-size: 28px !important;
      text-align: center !important;
    }

    .card-container {
      width: 82% !important;
      margin: 0 auto !important;
    }

    .card {
      flex-direction: column !important;
      height: 400px !important;
      width: 258px !important;
      padding: 0px !important;

      &:hover {}

      .card-image {}

      .card-title {}

      .card-description {}
    }
  }
}



.workplace-benefits {
  padding: 50px 130px;

  .title {
    color: #0C3557;
    // font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 60px;
    margin-bottom: 80px;
  }

  .card-container {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    padding-bottom: 1rem;
    border-radius: 12px;
    // box-shadow: 0px 4px 20px 0px rgba(0, 0, 0, 0.05);
    scrollbar-width: none;
    -ms-overflow-style: none;
    gap: 30px;

    &::-webkit-scrollbar {
      display: none;
    }

    .card {
      flex: 0 0 300px;
      background-color: #fff;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
      margin-right: 1.5rem;
      scroll-snap-align: start;
      transition: color 0.2s ease;


      &:hover {
        // background-color: #e3e3e3;
        transform: scale(1.05);
        transition: transform 0.2s ease-in-out, background-color 0.2s ease-in-out;
      }

      .card-image {
        width: 100%;
        height: 200px;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
      }

      .card-title {
        font-size: 1.2rem;
        font-weight: bold;
        margin: 1rem;
      }

      .card-description {
        font-size: 1rem;
        color: #555;
        margin: 0 1rem 1rem;
      }
    }
  }
}