@media only screen and (max-width : 480px) {
  .footer {
    width: 100% !important;
    padding: 20px 55px 10px 1px !important;
    box-sizing: border-box !important;

    .nav-ul {




      .nav-ul-items {


        .vertical {


          .footer-nav {}
        }

        .ant-menu-item {}
      }
    }

    .social-icons {


      .icon {}
    }

    .divider {}

    .footer-content {
      justify-content: space-between !important;
      width: 100% !important;
      padding: 8px 22px !important;
      flex-direction: column !important;

      .copyright {
        text-align: center !important;
      }

      .footer-links {

        text-align: center !important;

        .footer-link {}
      }
    }
  }
}



.footer {
  background: #0A8394;
  color: #fff;
  padding: 2rem;

  .nav-ul {
    display: flex;
    gap: 150px;
    padding: 0px 0px 30px 55px;



    .nav-ul-items {
      background-color: transparent;
      border-bottom: none;
      display: flex;

      .vertical {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .footer-nav {
          text-decoration: none;
          color: #fff;
        }
      }

      .ant-menu-item {
        font-size: 1.1rem;
        font-weight: bold;
        color: #fff;
        padding: 0 1.5rem;

        &:hover,
        &.ant-menu-item-selected {
          color: #f0f0f0;
        }
      }
    }
  }

  .social-icons {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
    gap: 16px;
    width: 100%;
    padding: 11px 55px 0px 55px;
    box-sizing: border-box;

    .icon {
      font-size: 24px;
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        color: #f0f0f0;
      }
    }
  }

  .divider {
    border: 0.5px solid;
    width: 94%;
    margin: 0px 46px;
    box-sizing: border-box;
  }

  .footer-content {
    display: flex;
    justify-content: space-between;
    padding: 5px 50px;

    .copyright {
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }

    .footer-links {
      margin-top: 10px;

      .footer-link {
        font-size: 0.9rem;
        color: #fff;
        text-decoration: none;
        margin: 0 0.8rem;

        &:hover {
          color: #f0f0f0;
        }
      }
    }
  }
}