@media only screen and (max-width : 480px) {
  .explore-jobs-section {
    flex-direction: column !important;
    margin: 0px !important;
    padding: 10px !important;
    box-sizing: border-box !important;

    .job-header {
      flex-direction: column !important;

      .explore-job-title {

        font-size: 30px !important;
        text-align: center !important;

      }

      .explore-job-content {
        text-align: center !important;

        p {}

        .explore-btn {
          padding-left: 100px !important;
          font-size: 17px !important;
        }
      }
    }

    .job-container {
      padding: 11px !important;
      margin-left: 38px !important;
    }


  }
}



.explore-jobs-section {
  display: flex;
  justify-content: space-around;
  margin: 90px;
  flex-direction: column;

  .job-header {
    display: flex;
    justify-content: space-around;

    .explore-job-title {
      color: #0C3557;
      // font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: 60px;
      /* 150% */
    }

    .explore-job-content {
      display: flex;
      flex-direction: column;

      p {
        color: #666;
        // font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .explore-btn {
        border: none;
        background: #ffffff;
        display: flex;
        align-items: flex-start;
        gap: 12px;
        color: #0A8394;
        cursor: pointer;
        font-size: 16px;
      }
    }
  }

  .job-container {
    width: 100%;
    display: flex;
    justify-content: flex-start; // Changed from space-around to flex-start
    padding: 35px;
    box-sizing: border-box;
    gap: 24px;
    overflow-x: auto; // Enable horizontal scrolling
    scrollbar-width: thin; // For Firefox
    scrollbar-color: #888 #f1f1f1; // For Firefox
    overflow-x: auto; // Enable horizontal scrolling
    scrollbar-width: thin; // For Firefox
    scrollbar-color: #888 #f1f1f1; // For Firefox

    // Limit the visible cards to 4
    max-width: calc(4 * (25% - 18px)); // Adjust based on card width and gap
    width: 100%;

    display: flex;
    gap: 24px;
    overflow-x: auto; // Enable horizontal scrolling

    // Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }

    // Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */

    max-width: calc(4 * (25% - 18px));
    width: 100%;

    >* {
      flex: 0 0 calc(25% - 24px);
      min-width: 250px;
      max-width: 300px;
    }

    scroll-snap-type: x mandatory;

    >.job-card {
      scroll-snap-align: start;
    }

  }
}