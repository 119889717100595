@media only screen and (max-width : 480px) {
  .job-card {
    border-radius: 12px;
    border: 1px solid #EEE;
    background: #F9F9F9;
    padding: 24px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    scroll-snap-align: start;
    transition: color 0.2s ease;
    flex: 0 0 calc(25% - 24px);
    min-width: 250px;
    max-width: 300px;
    // height: fit-content;

    // Remove 100% width to prevent squeezing
    width: auto;

    .job-title {
      font-size: 20px !important;
    }

    .job-role {
      padding: 12px 16px !important;
      font-size: 14px !important;
      margin: 6px -10px !important;
    }

    .job-location {}

    .learn-more {}
  }
}

.job-card {
  border-radius: 12px;
  border: 1px solid #EEE;
  background: #F9F9F9;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  scroll-snap-align: start;
  transition: color 0.2s ease;
  flex: 0 0 calc(25% - 24px);
  min-width: 250px;
  max-width: 300px;
  // height: fit-content;

  // Remove 100% width to prevent squeezing
  width: auto;

  .job-title {
    color: #0C3557;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 8px;
  }

  .job-role {
    font-size: 16px;
    color: #0A8394;
    margin-bottom: 16px;
    border-radius: 44px;
    background: linear-gradient(0deg, rgba(10, 131, 148, 0.10) 0%, rgba(10, 131, 148, 0.10) 100%), #FFF;
    width: fit-content;
    display: flex;
    padding: 4px 16px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .job-location {
    font-size: 14px;
    color: #666;
    margin-bottom: 24px;
  }

  .learn-more {
    text-decoration: none;
    border-radius: 12px;
    border: 1px solid #0A8394;
    width: fit-content;
    display: flex;
    height: 38px;
    padding: 0px 11px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    color: #0A8394;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    &:hover {
      text-decoration: underline;
    }
  }
}