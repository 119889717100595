// TestimonialCarousel.scss
@media only screen and (max-width : 480px) {
  .testimonial-carousel {


    .title {
      font-size: 29px !important;
      padding: 0px 14px !important;
      box-sizing: border-box !important;
    }

    .card-container {
      padding: 25px !important;
      box-sizing: border-box !important;
      width: 100% !important;

      .carousel-root {
        width: 100%;

        .carousel-arrow {

          &-prev {
            left: 0px !important;
          }

          &-next {
            right: 0px !important;
          }
        }

      }

      .carousel {
        width: 100% !important;
        max-width: 833px !important;

        // Carousel slide styling
        .slide {
          display: flex !important;
          justify-content: center !important;
          align-items: stretch !important;
        }

        // Navigation dots
        .control-dots {

          bottom: -30px !important; // Adjust dot positioning on mobile

        }
      }

      .card {
        flex-direction: column !important;
        width: 100% !important;
        padding: 30px !important;
        min-height: auto !important;
        gap: 15px !important;
        height: 800px !important;




        .card-image {
          width: 65% !important;
          transform: translateX(5%) !important;
        }

        .card-quote {
          width: 100% !important;
          text-align: center !important;

          .quotes {
            position: static !important;
            text-align: center !important;
            margin-bottom: 10px !important;
          }

          .designation {}

          p {
            font-size: 14px !important;
          }

          cite {
            text-align: center !important;

            .card-linkedin-link {
              justify-content: center !important;
            }
          }
        }
      }
    }

    .navigation {


      .nav-button {}

      .pagination {}
    }
  }
}

.testimonial-carousel {
  // padding: 24px 256px;

  .title {
    color: #0c3557;
    text-align: center;
    // font-family: Poppins;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    /* 64px */
  }

  .card-container {
    width: 100%;
    width: 100%;
    display: flex;
    justify-content: center;

    .carousel-root {
      width: 100%;

      .carousel-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;
        background-color: rgba(255, 255, 255, 0.8);
        border: 1px solid #e0e0e0;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
          background-color: #f0f0f0;
        }

        &-prev {
          left: 250px;
        }

        &-next {
          right: 280px;
        }

        svg {
          font-size: 20px;
          color: #0a8394;
        }
      }

      .control-dots {
        position: absolute;
        bottom: -40px;
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 0;
        margin: 0;
        list-style: none;

        .indicator {
          background-color: #d3d3d3;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: inline-block;
          margin: 0 8px;
          cursor: pointer;
          transition: background-color 0.3s ease;

          &.indicator-selected {
            background-color: #0a8394;
          }
        }
      }
    }

    .card {
      background-color: #fff;
      border-radius: 8px;
      // margin-right: 1.5rem;
      scroll-snap-align: start;
      transition: transform 0.3s ease;
      border-radius: 12px;
      border: 1px solid #eee;
      background: #fff;
      box-shadow: 0px 10.75px 26.875px 0px rgba(37, 58, 103, 0.1);
      transform: translateX(0%);
      display: flex;
      width: 833px;
      height: 402px;
      // padding: 64px 71px 64px 99px;
      // flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      flex-shrink: 0;
      margin: 0 auto;

      &.active {
        transform: translateX(0%);
      }

      .card-image {
        width: 40%;
        height: 100%;
        object-fit: cover;
        border-radius: 8px 8px 0 0;
        position: relative;
        transform: translateX(-11%);
      }

      .card-quote {
        color: #555;
        margin: 1.5rem;
        width: 87%;
        color: #090808;
        // font-family: "Noto Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 200%;
        /* 32px */

        .quotes {
          font-size: 85px;
          color: #0a8394;
          margin-top: 12px;
        }

        .designation {
          color: #777;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        p {
          margin-bottom: 1rem;
        }

        cite {
          display: block;
          text-align: left;
          color: #0a8394;
          // font-family: "Noto Sans";
          font-size: 20px;
          font-style: normal;
          font-weight: 800;
          line-height: normal;

          .card-linkedin-link {
            display: flex;
            font-size: 15px;
            font-weight: normal;
            color: #0077b6;
            text-decoration: none;
            padding: 22px 0px;
            gap: 5px;
            box-sizing: border-box;
          }
        }
      }
    }
  }

  .navigation {
    display: flex;
    justify-content: space-between;
    margin-top: 25px;

    .nav-button {
      background-color: #fff;
      border: none;
      padding: 0.5rem 8rem;
      cursor: pointer;
      margin: 0 0.5rem;
      color: #090808;
      // font-family: "Noto Sans";
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: gray;
    }

    .pagination {
      margin: 0 1rem;
      font-size: 15px;
      margin-top: 6px;
      color: gray;
    }
  }
}

.carousel-root {
  width: 100%;
}